<template>
  <v-touch id="main" class="pa-0 px-0 mx-0 ma-0" v-bind:style="{
    'position' : this.tables && this.tables.length > 0? 'static' : 'fixed'
  }" @left-swipe="previous()" @right-swipe="next()">
    <v-row align="center" class="mx-0 ma-0 px-0 pa-0">

      <v-col v-if="false"
             class="ma-auto text-center" cols="4" sm="2">
        <v-avatar
            v-if="!this.loading"
            tile
            class="elevation-0 pa-0 ma-auto"
            size="92"
            style="cursor: pointer !important;"
            v-bind:style="{
              'border' : '2px dashed grey',
              'background-color' : 'white',
              'border-radius': '10px !important'
            }"
            @click="switchToDirectSale"
        >
          <v-icon x-large>point_of_sale</v-icon>
        </v-avatar>
      </v-col>
      <v-col v-for="(table , index) in this.tables"
             v-show="(Boolean(chTable) === true && findTable(index)===-1) || Boolean(chTable) === false" :key="index"
             class="ma-auto text-center" cols="4" sm="2">
        <v-avatar
            :tile="table.type === 'rect'"
            class="elevation-0 pa-0 ma-auto"
            size="92"
            style="cursor: pointer !important;"
            v-bind:class="table.color + ' lighten-5'"
            v-bind:style="{
              'border' : '2px solid ' + $vuetify.theme.dark? 'white' : table.color,
              'background-color' : $vuetify.theme.dark? 'white' : hexToRGB(table.color),
              'border-radius': table.type === 'rect'? '10px !important': '200px',
            }"
            @click="selectTable(table , index)"
        >
          <v-row class="w-100" no-gutters>


            <v-col class="pt-1" cols="12">
              <!--              <b-badge class="table-client px-1">
                              {{ table.maxParties }}
                            </b-badge>-->
              <v-badge v-if="findTable(index)!==-1 && savedTables[findTable(index)].source!=='pos'" offset-y="7"
                       offset-x="-2" avatar color="grey">
                <template v-slot:badge>
                  <v-avatar size="20">
                    <v-img v-if="savedTables[findTable(index)].source!=='pos'" :src="bee" width="20" height="20"/>
                  </v-avatar>
                </template>
              </v-badge>
            </v-col>
            <v-row align="center" class="mt-3" justify="center" no-gutters>
              <v-col align-self="center" class="px-1 pt-0 mb-2 " cols="4">
                            <span v-if="findTable(index)!==-1" class="table-timer">
                                <v-progress-circular :key="timerLoading"
                                                     :color="getColor(index)[0]"
                                                     :value="getColor(index)[1]"
                                                     rotate="275"
                                                     size="20"
                                                     width="10"
                                />
                            </span>
              </v-col>
              <v-col align="center" align-self="center" class="pt-2 pb-2" cols="4">
                <h4 :class="[$vuetify.theme.dark? 'black--text' : 'white--text']"
                    class="table-name  font-weight-bolder" :style="{fontSize:tableNameFontSize+'px'}">
                  {{ table.name }}
                </h4>
              </v-col>
              <v-col align="end" align-self="center" class="px-1 mb-1 mx-auto" cols="4">
                            <span v-if="lockTable(index)" :class="[$vuetify.theme.dark? 'black--text' : 'white--text']"
                                  class="table-state mx-auto">
                                <font-awesome-icon :icon="['fal','lock-alt']"/>
                            </span>
              </v-col>
            </v-row>
            <v-col class="pb-3 pt-3 align-text-bottom align-bottom" cols="12">
              <span :style="{
                color: $vuetify.theme.dark? 'black !important' : 'white',
                backgroundColor: $vuetify.theme.dark? 'grey': 'black',
                opacity: $vuetify.theme.dark? 1 : 0.2
              }" v-if="findTable(index)!==-1"
                    class="mobile-table-total px-1 pt-1 ma-auto">
                            <span :style="{
                color: $vuetify.theme.dark? 'black !important' : 'white',
                opacity: 1
              }">
                              {{ getTotal(index)|currency }}
                            </span>
                        </span>
            </v-col>
          </v-row>
        </v-avatar>
      </v-col>
    </v-row>

    <v-bottom-navigation
        id="main-bottom-tables-nav"
        :color="this.colors.primary"
        :value="room_id"
        background-color="#D7DBEC"
        class="mx-0 ma-0 text-center elevation-1 border-bottom"
        fixed

    >
      <v-progress-linear v-if="this.loading && tables && tables.length === 0" :color="this.colors.primary" fixed
                         indeterminate/>
      <v-tabs v-model="tabs" :color="this.colors.primary" class="ml-0 border" @change="room_changed($event)">
        <v-tab
            v-for="room in this.roomsArr"
            :key="room.id"
            class=""
            :class="[$vuetify.theme.dark? '' : 'bg']"
            @click="getRoom(room.id)"
        >
          {{ room.room_name }}
        </v-tab>
      </v-tabs>
    </v-bottom-navigation>

    <v-dialog v-if="this.$vuetify.breakpoint.smAndDown" v-model="dialog.nbrParties" max-width="600px">
      <v-card>

        <v-card-title class="text-black text-left card-header-tab card-header pt-1 pl-4 pr-1 pb-1" elevation="0">
          <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
            {{ $t('generic.lang_numberOfParties') }}
          </div>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex style="margin-top: 20px;" xs12>
                <v-text-field v-model="dialog.nbrPartiesField" :label="$t('generic.lang_numberOfParties')"
                              :min="1"
                              :rules="[v=> (parseInt(v)>0) || this.$t('generic.lang_mustBeGreater0')]" type="number"/>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="card-footer pt-0 pb-0 text-right">
          <v-btn color="error" small text @click="dialog.nbrParties = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <template v-if="activeTable!==null&&activeTable!==undefined">
            <v-btn :disabled="canOrder" class="text-white mx-auto" color="#FF6969"
                   elevation="0" rounded small @click="createTable()">
              {{ $t('generic.lang_next') }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showCreateTable" width="500" @click:outside="closeAddTable">
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : 'grey lighten-2']" class="headline" primary-title>{{ $t('settings.lang_addTable') }}</v-card-title>
        <v-form v-model="validTableName" lazy-validation>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field type="text" :rules="[rules.checkZero,rules.required]" @keyup="checkPatternTable" v-model="tableName" :label="$t('generic.lang_table')"/>
            </v-col>

            <v-col cols="12">
              <v-text-field type="text" v-model="partyName" @keyup="checkPatternParty"  :label="$t('generic.lang_party')"/>
            </v-col>

            <v-col cols="12">
              <Numpad disableComma @clickButton="clickNumpad" @resetField="tableName = ''"/>
            </v-col>
          </v-row>
        </v-card-text>
        </v-form>

        <v-divider/>

        <v-card-actions>
          <v-btn @click="closeAddTable" text color="error">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="!validTableName" @click="addTable">{{ $t('generic.lang_create') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-model="loadingTableSelect">
      <v-progress-circular class="mx-auto" indeterminate size="150" color="primary"></v-progress-circular>
    </v-overlay>
  </v-touch>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {fal} from '@fortawesome/pro-light-svg-icons';
import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapGetters, mapState} from "vuex";
import Numpad from "@/components/pos/Numpad";
import validation from "../../../../mixins/validation/validation";

var sort = require('alphanum-sort');
library.add(
    fal
)
export default {
  components: {
    FontAwesomeIcon,
    Numpad
  },
  mixins:[validation],
  name: "MobileTable",
  props: {
    chTable: {
      type: Boolean,
      required: true
    },
    /**
     * we're using this props to pass colors palette into our component
     */
    colors: {
      type: Object,
      required: true
    },
    savedTables: {
      type: Array,
      default: null,
    },
    posType: {
      type: String,
      required: true
    },
    showCreateTable: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState([
      'api',
      'user',
      'pos'
    ]),
    ...mapGetters({
      socketTables:'pos/gastro/lockedTables',
    }),
    canOrder: function () {
      if (this.dialog.nbrPartiesField === null || this.dialog.nbrPartiesField === "") {
        return true;
      }
      if (parseInt(Number(this.dialog.nbrPartiesField)) > 0
          && parseInt(Number(this.dialog.nbrPartiesField)) <= parseInt(Number(this.activeTable.maxParties))) {
        return false;
      }
      return true
    }
  },
  data: () => {
    return {
      dialog: {
        nbrParties: false,
        nbrPartiesField: 1,
      },
      activeTable: null,
      index: null,
      time: 0,
      tableNameFontSize: 16,
      timer: null,
      loading: false,
      loadingTableSelect: false,
      validTableName: false,
      timerLoading: 0,
      roomsArr: [],
      tables: [],
      room_id: 0,
      tabs: 0,
      bg: 'bg-default',
      tableName: '',
      partyName: "Partei 1",
      bee: require('@/assets/images/delivery/bee.svg'),
      roomName: '',
      overrideInvoicePrinterId: null,
    }
  },
  beforeMount() {
    this.$store.dispatch("pos/setCustomer", {
      posType: this.posType,
      customer: null,
      setCustomerOnly:true,
    });
    this.$forceUpdate()
  },
  sockets: {
    "tableplan.refreshTables": function () {
      this.$emit("getTables");
    },
  },

  methods: {

    checkPatternParty() {
      this.partyName = this.partyName.replace(this.preventSpecialCharsRegex,'');
    },
    checkPatternTable() {
      this.tableName = this.tableName.replace(this.preventSpecialCharsRegex,'');
    },
    clickNumpad(value) {
      this.tableName += value;
    },
    closeAddTable() {
      this.$emit('closeAddTable');
    },
    orderItems(table, party, orderedItems) {
      return new Promise((resolve, reject) => {

        let FORM = {
          tableName: (table !== null ? table.name : 0),
          partyName: party.name,
          items: orderedItems,
          openItems: this.pos.gastro.openItems[party.name]
        };

        if (this.$store.getters['pos/gastro/isFromReservation']) {
          FORM.reservation_uuid = this.$store.getters['pos/gastro/reservationUUID'];
        }
        return this.axios.post(ENDPOINTS.POS.GASTRO.ORDER.CREATE, FORM).then((res) => {
          if (res.data.success || (!res.data.success && res.data.type === "warning")) {
            //EMIT SOCKET EVENT FOR KITCHENMONITOR
            this.$socket.emit("pos.createOrder", {
              table: {
                uuid: this.pos.gastro.table.uuid
              },
              items: orderedItems
            });

            //CHECK IF NOT TRESENVERKAUF
            if (table.name !== 0) {
              //ORDER ITEMS
              this.$store.commit("pos/gastro/setOrderedItems", {
                partyName: party.name,
                orders: orderedItems
              });

              Events.$emit('createOrder')

            }
          }
        });
      });
    },
    async changeTable(table) {
      let oldTable = Object.assign({}, this.pos.gastro.table)
      //  let currentTable = this.pos.gastro.
      let currentGastro = Object.assign(this.pos.gastro);
      const openItems = Object.assign(currentGastro.openItems);
      const orderedItems = Object.assign(currentGastro.orderedItems);
      const party = currentGastro.party;
      currentGastro.table.name = table.name;
      currentGastro.table.uuid = table.uuid;

      this.axios.post(ENDPOINTS.POS.GASTRO.CHANGETABLE, {
        sourceTable: oldTable.name,
        targetTable: table.name
      }).then((res) => {
        if (res.data.success) {
          //EMIT TO SOCKETSERVER THAT TABLE CHANGED
          this.$socket.emit('tableplan.changeTable', {
            oldTableUUID: oldTable.uuid,
            newTableUUID: table.uuid
          });

          this.$store.dispatch("pos/gastro/changeTable", {
            name: table.name,
            uuid: table.uuid
          }).then(() => {
            this.$router.replace("/pos/gastro");
          }).catch((err) => {
            //console.log(err);
          });
        } else {

        }
      }).catch(() => {

      })

      /*      this.$store.dispatch("pos/gastro/setTable", {
              posType: this.posType,
              table: currentGastro.table
            }).then(() => {

              /!**
               * delete the old one
               * *!/
              this.$store.dispatch("pos/gastro/setOpenItems", {
                party: party,
                orders: []
              });

              this.$store.dispatch("pos/gastro/setOrderedItems", {
                partyName: party.name,
                orders: []
              });

              this.$socket.emit("tableplan.setTable", {
                userID: this.user.userID,
                table: currentGastro.table
              });

              /!*        this.$socket.emit('tableplan.changeTable', {
                        oldTableUUID: this.pos.gastro.table.uuid,
                        newTableUUID: table.uuid
                      });*!/

              this.pos.gastro.party = party;
              this.pos.gastro.openItems = openItems;
              for (let elt in orderedItems)
                this.orderItems(table, {name: elt}, orderedItems[elt]);*/


      /*        this.$store.dispatch("pos/gastro/changeTable", {
                name: table.name,
                uuid: table.uuid
              }).then(() => {
                this.$router.replace("/pos/gastro");
              }).catch((err) => {
                //console.log(err);
              });*/
      //});
    },
    getGastroTableFromAPI(tablename) {

      let successFlag = false;
      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: ((tablename === null) ? 0 : tablename)
      }).then((res) => {

        if (res.data.success) {

          //set the customer if there is one

          if (res.data.customerId) {
            //console.log('before customer')
            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: res.data.customerId,
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer,
                  setCustomerOnly:true,
                })
              }
            }).catch((err) => {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_errorOccurred') + ' ' + this.$t('generic.lang_whileGettingTheCustomer') + ' : ' + err.message,
              });
            });
          } else {
            this.$store.dispatch("pos/setCustomer", {
              posType: this.posType,
              customer: null,
              setCustomerOnly:true,
            })
          }

          let cntr = 0;
          for (let partyName in res.data.orders) {
            ++cntr;
            if (cntr <= 1 && !Array.isArray(res.data.orders[partyName])) {
              continue;
            } else if (cntr <= 1) {
              //CLEAR TABLE PARTIES
              //this.$store.commit("pos/gastro/clearTableParties");
            }

            if (!res.data.orders.hasOwnProperty(partyName))
              continue;


            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: partyName,
              orders: res.data.orders[partyName]
            });

          }

          successFlag = true
          this.$store.dispatch('pos/gastro/setGastroOrder',res.data)
        } else {
          this.$store.dispatch('pos/gastro/setGastroOrder',null)
          successFlag = false
        }
      }).catch((err) => {
        this.loadingTableSelect=false;
        this.$swal({
          title: this.$t('generic.lang_errorOccurred'),
          text: "",
          icon: "error",
          confirmButtonText:this.$t('generic.lang_refreshPage'),
          showConfirmButton:true,
          showCancelButton: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.$router.go();
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
        successFlag = false
        this.$store.dispatch('pos/gastro/setGastroOrder',null)
      });
      return successFlag = false;
    },
    addTable() {
      this.closeAddTable()

      let index = this.findTableByName(this.tableName);

      if (index >= 0) {
        this.selectTable(this.tables[index], index);
      } else {
        index = this.findBookedTableByName(this.tableName);
        if (index >= 0) {
          this.selectTable(this.savedTables[index], index);
        } else {
          let date = new Date();
          let time = date.getHours() + ":" + date.getMinutes();
          this.$store.dispatch("pos/gastro/setTable", {
            posType: this.posType,
            table: {
              name: this.tableName,
              parties: [
                {
                  name: this.partyName
                }
              ],
              totalParties: 1,
              total: 0,
              date: time,
            }
          }).then(() => {
            this.$store.commit('pos/gastro/setRoom', {
              index: this.tabs,
              id: this.room_id,
              overrideInvoicePrinterId: this.overrideInvoicePrinterId,
            });
            //emiting an event to update the order in the mobilegrid component
            this.$root.$emit('updateTableOrders');
            this.$emit('Items', 'items');
          });
        }

      }
    },
    switchToDirectSale() {

      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: {
          name: 0,
          parties: [
            "partie 1"
          ]
        }
      }).then(() => {
        if (this.chTable) {
          //in case the customer canceled his order in the table to direct sale
          this.$store.commit('pos/gastro/enableDirectSaleMode');
          //emiting an event to update the order in the mobilegrid component
          this.$root.$emit('updateTableOrders');
          this.$emit('Items', 'items');
        } else {
          //clear cachier before switching to direct sale
          this.$store.dispatch("pos/gastro/setPagerNo", null);
          this.$store.commit('pos/gastro/setAdditionalStatistic', []);
          this.$store.commit('pos/gastro/setTakeAway', 0);
          this.$store.commit('pos/gastro/setTransactionPayload', {});
          this.$store.dispatch("pos/setCourse", {
            posType: this.posType,
            course: null,
          })
          this.$store.dispatch("pos/gastro/setOrderedItems", {
            partyName: this.pos.gastro.party.name,
            orders: []
          });
          this.$store.commit('pos/gastro/enableDirectSaleMode');
          //emiting an event to update the order in the mobilegrid component
          this.$root.$emit('updateTableOrders');
          this.$emit('Items', 'items');
        }
      });

    },

    selectTable(table, index) {
      // CHECK WEBSOCKET LOCK
      if (this.socketTables && this.socketTables.findIndex((tableName) => tableName === this.tables[index].name) > -1)
        return true;
      //CHECK IF WE ALREADY ACCESSING A TABLE
      if(this.loadingTableSelect)
        return;

      this.loadingTableSelect=true;

      this.getGastroTableFromAPI(table.name);

      let table_getted = this.savedTables[this.findTable(index)];
      let tmp = Object.assign(table, table_getted);


      if (this.chTable) {
        this.changeTable(table)
        //emiting an event to update the order in the mobilegrid component
        this.$root.$emit('updateTableOrders');
        this.$emit('Items', 'items');
        this.$emit('tableChanged');
      } else {
        if (tmp && tmp.parties === undefined) {
          this.activeTable = tmp;
          this.index = index;
          if (this.findTable(this.index) >= 0) {
            this.createTable();
            return;
          }

          if (tmp.dontAskForParties)
            this.createTable();
          else
            this.dialog.nbrParties = true;
          return;
        }
        if (tmp)
          tmp.roomName = this.roomName;

        this.$store.dispatch("pos/gastro/setTable", {
          posType: this.posType,
          table: tmp
        }).then(() => {
          this.$socket.emit("tableplan.setTable", tmp.name);
          this.$emit('Items', 'items');
          //emiting an event to update the order in the mobilegrid component
          this.$root.$emit('updateTableOrders');
          this.$store.commit('pos/gastro/setRoom', {
            index: this.tabs,
            id: this.room_id,
            overrideInvoicePrinterId: this.overrideInvoicePrinterId,
          });
        }).finally(()=>{
          this.loadingTableSelect=false;
        })
      }
    },
    createTable() {
      let date = new Date();
      let parties = [];
      let time = date.getHours() + ":" + date.getMinutes();
      this.tables[this.index].user = this.user.userID;

      //CHECK IF TABLE IS LOCKED
      if (!this.$store.getters['permissions/checkPermission']('allowAccessAllTables')) {
        if (this.lockTable(this.index))
          return;
      }

      if (this.findTable(this.index) >= 0) {
        parties = this.savedTables[this.findTable(this.index)]
      } else {
        for (let i = 0; i < parseInt(this.dialog.nbrPartiesField); i++) {
          parties.push({
            name: "Partei " + (i + 1),
          })
        }
      }
      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: {
          uuid: this.tables[this.index].uuid,
          name: this.tables[this.index].name.toString(),
          parties: parties,
          totalParties: 1,
          maxParties: this.tables[this.index].maxParties,
          total: 0,
          date: time,
          source: (this.findTable(this.index) >= 0) ? this.savedTables[this.findTable(this.index)].source : 'pos',
          roomName: this.roomName,
        }
      }).then(() => {
        this.$socket.emit("tableplan.setTable", this.tables[this.index].name);
        this.$store.commit('pos/gastro/setRoom', {
          index: this.tabs,
          id: this.room_id,
          overrideInvoicePrinterId: this.overrideInvoicePrinterId
        });
        //emiting an event to update the order in the mobilegrid component
        this.$root.$emit('updateTableOrders');
        this.$emit('Items', 'items');
      }).finally(()=>{
        this.loadingTableSelect=false;
      })

    },
    getTime() {
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.GET, {roomID: this.room_id})
          .then(res => {
            if (res.status === 200) {
              this.time = res.data.formfillData.textFields.roomTime;
              this.tableNameFontSize=res.data.formfillData.textFields.tableNameFontSize?res.data.formfillData.textFields.tableNameFontSize:16;
              this.overrideInvoicePrinterId = res.data.formfillData.textFields.overrideInvoicePrinterId;
            }
          }).catch(err => {
      });
    },
    sync() {
      let self = this;
      this.timer = setInterval(() => {
        self.timerLoading += 0.1;
      }, 1000 * 30);
    },
    room_changed(event) {
      ////console.log('event', event)
    },
    lockTable(index) {

      // CHECK WEBSOCKET LOCK
      if (this.socketTables && this.socketTables.findIndex((tableName) => tableName === this.tables[index].name) > -1)
        return true;

      // CHECK SAVED TABLE
      if (this.findTable(index) !== -1) {
        let table = this.savedTables[this.findTable(index)];

        if (parseInt(table.soldBy) > 0)
          if ((table.soldBy !== this.user.userID))
            return true;
      }

      return false;
    },
    getTotal(index) {
      //GET TOTAL FROM WEBSOCKET
      let tableUUID = this.tables[index].uuid;
      if (this.socketTables.hasOwnProperty(tableUUID))
        return this.socketTables[tableUUID].total;

      return this.$props.savedTables[this.findTable(index)].total;
    },
    findBookedTableByName(name) {
      let tables = this.$props.savedTables;
      if (tables && tables.length > 0) {
        for (let i = 0; i < tables.length; i++) {
          if (name.toString() === tables[i].name.toString()) {
            return i;
          }
        }
      }
      return -1;
    },
    findTable(index) {
      let tables = this.$props.savedTables;
      if (tables && tables.length > 0) {
        for (let i = 0; i < tables.length; i++) {
          if (this.tables[index].name.toString() === tables[i].name.toString()) {
            return i;
          }
        }
      }
      return -1;
    },
    findTableByName(name) {
      let tables = this.tables;
      if (tables && tables.length > 0) {
        for (let i = 0; i < tables.length; i++) {
          if (name.toString() === tables[i].name.toString()) {
            return i;
          }
        }
      }
      return -1;
    },
    next() {
      if (this.roomsArr && this.tabs < this.roomsArr.length) {
        this.tabs++;
        this.getRoom(this.roomsArr[this.tabs].id);
      }
    },
    previous() {

      if (this.tabs > 0) {
        this.tabs--;
        this.getRoom(this.roomsArr[this.tabs].id);
      }
    },
    hexToRGB(h) {
      let r = 0, g = 0, b = 0;

      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
      } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
      }

      return "rgb(" + +r + "," + +g + "," + +b + "," + 0.3 + ")";
    },
    getColor(index) {
      if (this.$props.savedTables !== null) {
        let percentage = 0.80,
            hue0 = 120, hue1 = 0;
        let now = Date.now();
        let roomTime = this.time * 60 * 1000;
        let tableTime = this.$props.savedTables[this.findTable(index)].timestamp * 1000;
        percentage = (((now - tableTime) / roomTime)).toFixed(2);
        percentage = (percentage > 1) ? 1 : percentage;
        let hue = (percentage * (hue1 - hue0)) + hue0;
        return ['hsl(' + hue + ', 100%, 40%)', Math.floor(percentage * 100).toFixed(2)];
      }
    },
    /**
     * we need this function to get get tables in specific room after a user interaction with tabs
     * @param id
     */
    getRoom(id) {
      //this.$emit('input', id)
      this.room_id = id;
      this.loadTables(id);
    },
    sortTableName(tables) {
      const sorted = (a, b) => a.name.localeCompare(b.name, 'en', {numeric: true});
      if (tables)
        return tables.sort(sorted);
    },
    loadTables(id) {
      this.tables = [];
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.DETAILS, {
        roomID: id
      })
          .then((res) => {
            this.sync();
            this.getTime();
            this.roomName = res.data.formfillData.textFields.roomName
            this.tables = this.sortTableName(JSON.parse(res.data.formfillData.textFields.interior_object).tables);
            this.bg = JSON.parse(res.data.formfillData.textFields.interior_object).background;

            //CALL SOCKET IO TO GET LOCKED TABLES
            this.$socket.emit("tableplan.getLockedTables");

          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
    ,
    async rooms() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.ROOMS)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              this.roomsArr = res.data.rooms || [];

              if (this.roomsArr && this.roomsArr.length > 0) {
                if (this.room_id === 0)
                  this.room_id = this.roomsArr[this.pos.gastro.lastRoom.index].id;
                this.getRoom(this.room_id);
              }
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  mounted() {
    /**
     * will  apply after asking Omar
     */
    if (this.$route.query.hasOwnProperty("reActivateSale")) {

      //TEMPORARY FIX FOR REACTIVE SALE IN MOBILE
      //*START*//
      this.loadTables(this.room_id);

      this.rooms().then(() => {
        this.tabs = this.pos.gastro.lastRoom.index;
      })
      //*END*//
      return;
    }

    if (this.$route.query.source === 'rfid') {
      //emiting an event to update the order in the mobilegrid component
      this.$root.$emit('updateTableOrders');
      this.$emit('Items', 'items');
      this.$router.replace({'query': {'source': null}});
      return;
    }

    if (!this.chTable) {
      this.$store.dispatch("pos/gastro/changeParty", {
        posType: this.posType,
        party: null
      });

      this.$store.dispatch("pos/gastro/setTable", {
        posType: this.posType,
        table: null
      })
    }
    /**
     * end ask
     */
    this.loadTables(this.room_id);

    this.rooms().then(() => {
      this.tabs = this.pos.gastro.lastRoom.index;
    })

  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>


<style scoped>
.bg {
  background-color: #E6E9F4 !important;
}
</style>
<style>

#main {
  height: 100% !important;

  overflow-y: visible !important;
}

#main-bottom-tables-nav {
  bottom: 62px !important;
  height: 40px !important;
}

#main-bottom-tables-nav .v-slide-group__prev {
  display: none !important;
}


#main .room-table-filter {
  display: block;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0px 0px 3px 3px;
}

#main .table-name {
  overflow: hidden;
  user-select: none;
  text-shadow: 1px 2px 3px #666 !important;
}

#main .table-client {
  text-align: center;
  min-height: 19px !important;
  min-width: 19px !important;
  font-size: 10px;
  padding-top: 4px;
  border-radius: 50%;
  background: #FF6969;
  color: white;
  z-index: 0;
}

#main .mobile-table-total {
  margin-bottom: 10px;
  text-align: center;
  height: 25px;

  min-width: 40px;
  z-index: 0;
  font-size: 16px;
  user-select: none;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 24px) !important;
}

#main .table-state {
  text-align: center;
  height: 20px !important;
  width: 20px !important;
  z-index: 0;
  font-size: 18px;
  user-select: none;
}

#main .table-timer {
  text-align: center;
  height: 7px !important;
  width: 7px !important;
  z-index: 0;
  font-size: 10px !important;
  color: white;
  user-select: none;
}

.localbee-img {
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  margin-bottom: 5px;
  background: black;
  color: white;
  opacity: 0.2;
}

</style>
